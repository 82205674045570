@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

/* global.css */
:root {
  --primary: #66B28C;
  --background: #ECECEC;
  --text: #333333;
  --grey: #666666;
  /* other light mode variables */
}

[data-theme="dark"] {
  --primary: #66B28C;
  --background: #121212;
  --text: #ffffff;
  --grey: #C7C7CC;
  /* other dark mode variables */
}


body{
  font-family: 'poppins';
  background-color: var(--background);
  color: var(--text);
  transition: background-color 0.3s, color 0.3s;
}
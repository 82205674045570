.Home {
    padding: 40px 40px;
    /* min-height: 150vh; */
}

.homeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.grid {
    display: grid;
    /* grid-template-columns: repeat(3, 1fr); */
    /* Always 2 columns */
    gap: 1rem;
}

.grid>* {
    max-width: 100%;
    /* Prevent stretching */
}


.sidebar {
    width: 300px;
    /* Fixed width for the sidebar */
    flex-shrink: 0;
    /* Prevent the sidebar from shrinking/stretching */
}

.FiltersDiv {
    border: 1px solid #CBCBCB;
    background-color: #F1F1F1;
    padding: 40px 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 250px;
    transition: all 80ms ease-in-out;
    border-radius: 4px;
}
.FiltersDiv.dark{
    border: 1px solid #333333;
    background-color: #1B1D1C;
}

.FiltersDiv.hide {
    opacity: 0;
    padding: 0;
    overflow-x: hidden;
    max-width: 0;
    border: none;
}

.vidSaveBtn {
    background-color: rgb(36, 140, 158);
    color: white;
    border-radius: 100px;
    padding: 6px 16px;
    padding-right: 20px;
    font-size: 14px;
}

.vidSaveBtn:hover {
    background-color: rgb(36, 124, 139);
}

.shadow {
    box-shadow: 0px 5px 10px #0000001a;
}

.greenButton{
    color: black;
    background-color: var(--primary);
    padding: 10px 24px;
    border-radius: 100px;
    width: max-content;
    cursor: pointer;
    font-weight: 500;
}

.lightGreenButton{
    color: black;
    background-color: #E6F6EE;
    border: 0.5px solid black;
    padding: 6px 16px;
    border-radius: 100px;
    width: max-content;
    cursor: pointer;
    font-weight: 300;
    font-size: 12px;
}
.lightGreenButton.dark{
    color: white;
    background-color: #0E1914;
    border: 0.5px solid white;
}

.button{
    color: var(--text);
    background-color: transparent;
    border: 0.5px solid var(--text);
    padding: 10px 24px;
    border-radius: 100px;
    width: max-content;
    cursor: pointer;
    font-weight: 300;
}

@media only screen and (max-width: 768px) {
    .Home {
        padding: 40px 20px;
        font-size: 14px;
    }

    .FiltersDiv {
        padding: 16px 0;
        max-width: 156px;
    }
}
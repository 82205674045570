.NavContainer{
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 20;
}
.Nav {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    align-items: center;
    color: var(--grey);
    padding: 0px 40px;
    height: 72px;
    font-size: 15px;
}
.NavContainer.dark {
    background: radial-gradient(100% 100% at 50% 0%, #10130D 0%, #10130D 100%);
}

.navLogo{
    height: 24px;
}

.navSearchDiv {
    min-width: 400px;
    max-width: 400px;
}

.navSearch {
    background-color: #F2F2F7B8;
    border-radius: 30px;
    padding: 16px 16px;
    padding-right: 40px;
    width: 100%;
    border: 1px solid #CFCFCF;
    height: 100%;
    color: black !important;
}

.navSearch:hover {
    border: 1px solid rgba(255, 255, 255, 0.233);
}

.navSearch:focus {
    border-radius: 16px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.navBtn {
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
}

.navBtn:hover {
    /* font-weight: 600; */
    /* background-color: rgb(252, 185, 161); */
}

.suggestions-dropdown {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #F2F2F7B8;
    border: 1px solid #ddd;
    border-radius: 16px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 100;
    padding: 10px 0;
    max-height: 130px;
    overflow-y: scroll;
}

.suggestions-dropdown::-webkit-scrollbar {
    display: none;
}

.suggestion-item {
    padding: 8px 16px;
    padding-left: 40px;
    color: rgb(39, 39, 39);
    cursor: pointer;
}

.suggestion-item:hover {
    color: black;
}


.navStrip{
    background: linear-gradient(-90deg, #10130D 0%, #4B8166 100%);
}

@media only screen and (max-width: 768px) {
    .Nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        font-size: 15px;
    }

    .navSearchDiv {
        min-width: auto;
        max-width: unset;
        width: 100%;
    }

    .navSearch {
        padding: 8px 16px;
        padding-left: 40px;
        width: 100%;
        height: 100%;
    }

    .suggestions-dropdown {
        top: 50px;
    }

}